@charset 'UTF-8';
@import './setting/variables';

.GPC0047 {
	margin:0 auto;
	text-align:center;
	//@extend %clearfix;
	.title, .title h1, .title h2, .title h3, .title h4, .title h5, .title h6  {
		@include font-family($font-semibold);
		max-width:1284px;
		margin:0 auto 43px auto;
		font-size:40px;
		line-height:48px;
		@include screen(custom, max, $max-lg) {
			padding:0 30px 0 30px;
		}
		@include screen(custom, max, $max-sm) {
			padding:0 6px 0 6px;
			font-size:28px;
			line-height:32px;
			margin-bottom:29px;
		}
	}
	.component-outer-box{
		position:relative;
		.list-box{
			text-align:center;
			overflow:hidden;
			.slick-prev {
				margin-top:-37px;
				left:30px;
				[dir="rtl"] & {
					left:auto;
					right:30px;
				}
			}
			.slick-next {
				margin-top:-37px;
				right:30px;
				[dir="rtl"] & {
					right:auto;
					left:30px;
				}
			}
			.slick-list{
				padding-bottom: 11px;
			}
			.slick-track{
				@extend %clearfix;
				margin:0 auto 0 auto
			}
		}
	}

	.non-carousel-box{
		max-width:1284px;
		margin:0 auto;
		padding-left:0px;
		padding-right:0px;
		text-align:center;
		@include screen(custom, max, $max-lg) {
			padding-left:24px;
			padding-right:24px;
		}
		@include screen(custom, max, $max-xs) {
			padding-left:0px;
			padding-right:0px;
		}

		&.item5{
			.items{
				max-width:1066px;
			}
		}
		&.item4{
			.items{
				max-width:852px;
			}
		}
		&.item3{
			.items{
				max-width:638px;
			}
		}
		&.item2{
			.items{
				max-width:424px;
			}
		}
		.items{
			text-align:center;
			margin:0 auto;
			.item {
				vertical-align:top;
				float:none;
				display:inline-block;
				width:160px;
				margin:0 25px 0 25px;
				padding-bottom:23px;
				@include screen(custom, max, $max-sm) {
					width:124px;
					margin:0 10px 0 11px;
				}
			}
		}

	}

	.item {
		float:left;
		[dir="rtl"] & {
			float:right;
		}
		width:calc(100%/6);
		@include screen(custom, max, $max-sm) {
			width:100%;
		}
		.visual-area {
			position:relative;
			max-width:160px;
			margin:0 auto 14px auto;
			a {
				display:block;
			}
			img {
				max-width:100%;
			}
		}
		.caption {
			max-width:160px;
			margin:0 auto 11px auto;
			font-size:16px;
			line-height:24px;
			color:$color-dimgray;
			display:block;

		}

		.bottom-btn {
			max-width:160px;
			margin:0 auto;
			.link-text {
				color:$color-nightrider;
				//BTOBGLOBAL-755 Start
				word-break: break-word;
				//BTOBGLOBAL-755 End
			}
			.ico-right{
				&:after {
					background: url($link-right-nightrider) no-repeat 0 1px; //LGCOMSPEED-6(8th)
				}
			}

			&.btn-type-box {
				.btn{
					margin:0 0 10px 0;
				}
			}
			&.btn-type-text {
				.link-text{
					margin:0 0 11px 0;
				}
			}

		}
	}

	// slick slider
	.slick-slider {
		button {
			appearance:none;
			border:none;
			background-color:transparent;
			// 20200413 START 이상현 - GPC0047 : rtl css 수정
			overflow: hidden;
			text-indent:-10em;
			[dir="rtl"] {text-indent:10em;}
			// 20200413 END
		}
		.slick-arrow {
			line-height:0;
			
			color:$color-gray;
			@include screen(custom, max, ($max-sm)) {
				display:none !important;// 모바일에서는 좌우 화살표 출력하지 않음
			}
			&:not([disabled]):hover,
			&:not([disabled]):focus {
				//olor:$color-nightrider;
				color:$color-black;
			}
			svg {display:block;}
		}
		
		.slick-dots {
			text-align: center;
			margin:0;
			height:10px;
			li {
				position:relative;
				display:inline-block;
				vertical-align:top;
				width:10px;
				height:10px;
				margin:0 4px;
				border-radius:50%;
				border:1px solid $color-gray;
				line-height:normal;
				a, button {
					background: $color-white;
					display:block;
					width:100%;
					height:100%;
					margin:0;
					padding:0;
					border:0;
					border-radius:50%;
					overflow: hidden;
					color:transparent;
					cursor: pointer;
					&:focus {
						outline:1px dotted;
						outline:5px auto -webkit-focus-ring-color;
					}
				}
				&.slick-active {
					border:1px solid $color-carmine;
					a, button {
						background:$color-carmine;
					}
				}
				&:only-child {
					display:none;
				}
			}
		}
	}

	&.js-invisible-nav {
		.slick-indicator,
		.slick-arrow {
			z-index: -1 !important;
		}
	}
}
